<template>
  <a-modal
    :width="600"
    centered
    :visible="visible"
    :footer="null"
    :closable="false"
  >
    <div class="container">
      <a-alert
        v-if="errorMessage"
        class="mb-3"
        :message="errorMessage"
        type="error"
        showIcon
        closable
        @close="() => (errorMessage = '')"
      />
      <div class="d-flex justify-content-between">
        <span class="small sat-btn-link">เพิ่มสิทธิ์การใช้งาน</span>
        <div v-if="formRef || isSentEmail">
          <a-button type="link" class="me-2" @click="dismissForm"
            >ละทิ้ง</a-button
          >
          <template v-if="!isSentEmail">
            <a-button
              v-if="!isChangedPermission || !disableSelectPerm"
              type="primary"
              @click="handleFormFinish"
              :disabled="!formState.email || !formState.permission"
              :loading="updatingUser"
              >บันทึก</a-button
            >
            <a-button
              v-else
              type="primary"
              @click="() => sendConfirmEmail()"
              :loading="sendingEmail"
              >ส่งอีเมลยืนยันอีกครั้ง</a-button
            >
          </template>
        </div>
      </div>
      <transition name="fade">
        <div v-if="isSentEmail" class="text-center">
          <div v-if="!isChangedToDefalut">
            <p class="h1 mb-0" :style="{ fontSize: '7rem' }">
              <IconSVG name="sentEmail" />
            </p>
            <h3>ส่งยืนยันสิทธ์ ไปที่อีเมลแล้ว</h3>
            <p class="small text-muted">{{ formState.email }}</p>
            <p class="text-muted">การยืนยันสิทธ์มีอายุ 2 วัน นับจากวันนี้</p>
          </div>
          <div v-else>
            <p class="h1 mb-5" :style="{ fontSize: '7rem', color: '#3DD598' }">
              <CheckCircleOutlined />
            </p>
            <h3>เปลี่ยนแปลงสิทธิ์การใช้งาน<br />เป็น Default แล้ว</h3>
            <p class="small text-muted">{{ formState.email }}</p>
          </div>
        </div>
      </transition>

      <template v-if="!isSentEmail">
        <p class="fw-bold">ค้นหาจากนักกีฬา/บุคลากร</p>
        <a-select
          label-in-value
          v-model:value="selectedValue"
          placeholder="Select users"
          style="width: 100%"
          size="large"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchUser"
          show-search
          option-label-prop="label"
          @select="onSelectedUser"
        >
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option
            v-for="d in data"
            :key="d.value"
            :label="d.text"
            :data-email="d.email"
          >
            <span class="d-flex align-items-center">
              <!-- <a-avatar :src="d.user.picture.large" class="me-2" /> -->
              <a-avatar class="me-2" :size="48">{{ "" }}</a-avatar>
              <span>
                <span class="d-block">{{ d.text ?? "-" }}</span>
                <span class="small text-muted fw-light">{{
                  d.citizenId ?? "-"
                }}</span>
              </span>
            </span>
          </a-select-option>
        </a-select>
        <a-divider />
        <p class="fw-bold">ข้อมูลส่วนสำคัญ</p>
        <a-form
          layout="vertical"
          ref="formRef"
          :model="formState"
          @finish="handleFormFinish"
        >
          <a-form-item label="อีเมลที่ใช้ในการยืนยันสิทธิ์ใช้งาน">
            <a-input
              v-model:value="formState.email"
              placeholder="E-mail address"
              size="large"
              disabled
            />
          </a-form-item>
          <a-form-item label="ระดับสิทธิ์การใช้งาน">
            <a-select
              v-model:value="formState.permission"
              placeholder="Select permission"
              size="large"
              :disabled="disableSelectPerm"
            >
              <a-select-option
                v-for="ele in permissionOptions"
                :key="ele.id"
                :value="ele.id"
                :label="ele.permission_name_en"
                >{{ ele.permission_name_en }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>
      </template>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { bigDataService, academyService, eLearningService, reportService } from "@/_services";
import { IPageResponse, IPermission } from "@/_utils/types";
import IconSVG from "@/components/IconSVG.vue";
import {
  defineComponent,
  UnwrapRef,
  ref,
  reactive,
  watch,
  toRefs,
  toRef,
  computed,
  toRaw,
} from "vue";
import { useRequest } from "vue-request";
import { CheckCircleOutlined } from "@ant-design/icons-vue";
interface FormState {
  email: string;
  permission?: string;
}
interface SelectorState {
  selectedValue:
    | {
        key: string;
        value: string;
        label: string;
      }
    | undefined;
  data: {
    text: string;
    value: string;
    email: string;
    citizenId: string;
  }[];
  fetching: boolean;
}
const defaultPermission = undefined;
export default defineComponent({
  props: {
    visible: Boolean,
    type: {
      type: String,
      validator: (val: string) =>
        ["bigData", "academy", "eLearning"].includes(val),
    },
    accountId: {
      type: Number,
      required: false,
    },
  },
  components: {
    IconSVG,
    CheckCircleOutlined,
  },
  setup(props, { emit }) {
    const type = toRef(props, "type");
    const service =
      type.value === "bigData"
        ? bigDataService
        : type.value === "academy"
        ? academyService 
        :type.value === "eLearning"?
        eLearningService : reportService;
    const accountId = toRef(props, "accountId");
    const isVisible = toRef(props, "visible");
    const selectedAccountId = ref(accountId.value);
    const isChangedPermission = ref<boolean>(false);
    const permissionOptions = ref<IPermission[]>([]); //reactive<{value: IPermission[]}>({value:[]})
    const defaultPermissionId = ref(-1);
    const formRef = ref();
    const errorMessage = ref<string>("");
    const isSentEmail = ref<boolean>(false);
    const isChangedToDefalut = ref(false);
    const disableSelectPerm = ref<boolean>(false);
    const formState: UnwrapRef<FormState> = reactive({
      email: "",
      permission: defaultPermission,
    });
    const state = reactive<UnwrapRef<SelectorState>>({
      data: [],
      selectedValue: undefined,
      fetching: false,
    });
    const isVerifiedEmail = ref<boolean>(false);
    watch([isVisible, accountId], () => {
      if (isVisible.value) {
        if (accountId.value) {
          selectedAccountId.value = accountId.value;
          // fetchUsers({page: 1, account_id: accountId.value})
        } else {
          fetchUsers({ page: 1, size: 10 });
        }
      }
    });

    watch(selectedAccountId, () => {
      if (selectedAccountId.value) {
        fetchUsers({ page: 1, account_id: selectedAccountId.value });
      }
    });

    const onSelectedUser = (selected: any, option: never) => {
      if (+selected.value) {
        selectedAccountId.value = +selected.value;
        // fetchUsers({page: 1, account_id: +selected.value})
      }
      // formState.email = option["data-email"];
    };

    const handleFormFinish = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const selectedUser = toRaw(state.selectedValue) as any
      const permissionId = formState?.permission ?? "";

      if (+permissionId === +defaultPermissionId.value) {
        isChangedToDefalut.value = true;
      }
      const userId = state.selectedValue?.value;
      errorMessage.value = "";
      if (permissionId && userId) {
        updateUser(+userId, +permissionId);
        isSentEmail.value = true
      }
    };
    const dismissForm = () => {
      const isSave = isSentEmail.value;
      formState.email = "";
      formState.permission = defaultPermission;
      if (formRef.value) formRef.value.resetFields();
      state.selectedValue = undefined;
      isChangedPermission.value = false;
      isChangedToDefalut.value = false;
      isSentEmail.value = false;
      errorMessage.value = "";
      selectedAccountId.value = -1;
      emit("closeModal", isSave);
    };

    const { data: permissions } = useRequest(service.getPermissions, {
      ready: computed(() => !!type.value),
    //   onSuccess: () => {
    //     if (permissions.value?.data) {
    //       const { data } = permissions.value?.data as IPageResponse<
    //         IPermission[]
    //       >;

    //       permissionOptions.value = data;
    //       console.log('data',data)
    //       const defaultPerm = data.find((ele) =>
    //         ele.permission_code.includes("DEFAULT")
    //       );
    //       defaultPermissionId.value = defaultPerm?.id ?? -1;
    //     }
    //   },
    });
    watch([permissions],()=>{
        if (permissions.value?.data) {
          const { data } = permissions.value?.data as IPageResponse<
            IPermission[]
          >;

          permissionOptions.value = data;
          const defaultPerm = data.find((ele) =>
            ele.permission_code.includes("DEFAULT")
          );
          defaultPermissionId.value = defaultPerm?.id ?? -1;
        }
    })

    const onFetchSuccess = () => {
      if (result.value?.data) {
        const users = result.value?.data;
        const options = users.data.map((user) => {
          const { account_informations, account_id, account_email } =
            user.accounts;
          const {
            account_information_firstname_en: fEn,
            account_information_firstname_th: fTh,
            account_information_lastname_en: lEn,
            account_information_lastname_th: lTh,
            account_information_citizen_id: citizenId,
          } = account_informations;
          return {
            text: `${(fEn || fTh) ?? "N/A"} ${
              fEn ? lEn ?? "N/A" : lTh ?? "N/A"
            }`,
            value: `${account_id}`,
            email: account_email,
            citizenId,
          };
        });
        state.data = options;
        if (selectedAccountId.value) {
          const focusedUser = users.data
            .map((e) => e.accounts)
            .find((ele) => +ele.account_id === selectedAccountId.value);
          const option = state.data.find(
            (ele) => ele.value === `${selectedAccountId.value}`
          );
          if (option) {
            state.selectedValue = {
              key: `${option.value}`,
              label: option.text,
              value: option.value,
            };
            formState.email = option.email;
          }
          if (focusedUser) {
            const roles = (users.data[0] as any).role_permission.permissions;
            formState.permission = roles.id;
            isChangedPermission.value = !focusedUser.account_email_verified;
            isVerifiedEmail.value = focusedUser.account_email_verified;
            disableSelectPerm.value =
              isChangedPermission.value && !isVerifiedEmail.value;
            // Every permission menu is false
            if (
              Object.values(roles.permission_menu).every((val) => val === false)
            ) {
              disableSelectPerm.value = false;
            }
            // isSentEmail.value = true //show modal email sent
          }
        }
      } else {
        state.data = [];
      }
      state.fetching = false;
    };

    const {
      data: result,
      loading: fetchingUsers,
      run: fetchUsers,
    } = useRequest(service.getAccounts, {
      debounceInterval: 300,
      // ready: computed(() => type.value === 'bigData'),
      defaultParams: [{ page: 1, size: 10 }],
      //   onSuccess: onFetchSuccess,
    });

    watch([result], () => {
      onFetchSuccess();
    });
    const { loading: updatingUser, run: updateUser } = useRequest(
      service.updatePermissionAccount,
      {
        manual: true,
       //  onSuccess: (result) => {
        //   console.log("result", result);

          // isChangedPermission.value = true
         //  if (accountId.value) {
            // runSendConfirmEmail(accountId.value);
          //   isSentEmail.value = true;
        //   }
       //  },
        onError: (error) => {
          const { message } = error;
          if (message) {
            errorMessage.value = message;
          }
        },
      }
    );

    watch([accountId], () => {
      if (accountId.value) {

        // runSendConfirmEmail(accountId.value);
        // isSentEmail.value = true;
      }
    });
    const { run: runSendConfirmEmail, loading: sendingEmail } = useRequest(
      service.resendEmail,
      {
        manual: true,
        onSuccess: (result) => {
          const { success } = result;
          if (success) {
            isSentEmail.value = true;
          }
          //
          // dismissForm
        },
      }
    );

    const sendConfirmEmail = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selectedUser = toRaw(state.selectedValue) as any;
      runSendConfirmEmail(selectedUser.value);
    };

    const fetchUser = (value: string) => {
      state.fetching = true;
      state.data = [];
      fetchUsers({
        page: 1,
        size: 5,
        name: value,
      });
    };

    return {
      formRef,
      formState,
      ...toRefs(state),
      fetchUser,
      fetchingUsers,
      onSelectedUser,
      handleFormFinish,
      dismissForm,
      permissionOptions,
      updatingUser,
      isChangedPermission,
      sendConfirmEmail,
      sendingEmail,
      errorMessage,
      isSentEmail,
      isVerifiedEmail,
      disableSelectPerm,
      isChangedToDefalut,
    };
  },
});
</script>
